.footer {
  background-color: #000;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__config {
  display: flex;
  color: #BDBDBDC7;
  justify-content: center;
  margin: 0;
  padding-left: 5px;
}

.footer__privacy {
  color: #BDBDBDC7;
  text-decoration: none;
  padding-left: 5px;
  font-weight: 600;
}

.footer__privacy.active {
  border: none;
  padding: 0;
}

.hover:hover {
  opacity: .8;
  transition: 0.7s ease;
}

@media screen and (max-width: 650px) {
  .footer {
    flex-direction: column;
  }
}