.page {
  background-color: #fff;
}

.page__content {
  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  font-family: 'Inter', 'Calibri', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  display: flex;
    flex-direction: column;
}