.contacts {
  background-color: #F2F2F2;
  padding: 100px 0;
}

.contacts__info {
  margin: 0 auto;
  width: calc(100% - 100px);
}

.contacts__paragraph {
  word-wrap: break-word;
  white-space: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 34px;
  text-decoration: none;
  text-transform: uppercase;
  padding-bottom: 10px;
  width: 59%;
}

.contacts__text_adress {
  word-wrap: break-word;
  white-space: normal;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none;
  padding-bottom: 12px;
}

.contacts__text_adress::before {
  content: url('../../images/blackEMail.svg');
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

.product__text_inn {
  word-wrap: break-word;
  white-space: normal;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none;
  padding-bottom: 12px;
  display: flex;
}

.product__text_inn::before {
  content: url('../../images/check.svg');
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

@media screen and (max-width: 900px) {
  .contacts {
    padding: 60px 0;
  }

  .contacts__paragraph {
    font-size: 25px;
    padding-bottom: 5px;
    width: 80%;
  }

  .product__text_inn {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .contacts {
    padding: 50px 0;
  }

  .contacts__paragraph {
    font-size: 21px;
    padding-bottom: 10px;
    width: 85%;
  }

  .contacts__text_adress {
    font-size: 16px;
    padding-bottom: 12px;
  }

  .contacts__info {
    width: calc(100% - 60px);
  }
}

@media screen and (max-width: 500px) {
  .contacts {
    padding: 50px 0;
  }

  .contacts__paragraph {
    font-size: 16px;
    padding-bottom: 5px;
    width: 92%;
  }

  .contacts__text_adress {
    font-size: 14px;
    padding-bottom: 15px;
  }

  .product__text_inn {
    font-size: 14px;
  }

  .contacts__info {
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 400px) {
  .contacts {
    padding: 30px 0;
  }

  .contacts__paragraph {
    font-size: 15px;
    padding-bottom: 0px;
    width: 100%;
    line-height: 24px;
  }

  .contacts__text_adress {
    font-size: 14px;
    padding-bottom: 15px;
    line-height: 17px;
  }

  .product__text_inn {
    font-size: 13px;
    line-height: 14px;
  }

  .contacts__info {
    width: calc(100% - 20px);
  }
}