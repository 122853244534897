.slider {
  display: none;
}

@media screen and (max-width: 768px) {
  .slider {
    display: flex;
    overflow-x: hidden;
  }

  .slider__one,
  .slider__two,
  .slider__three {
    flex: 0 0 auto;
    width: 100%;
    transition: transform 0.5s ease;
  }

  .slider__one-text,
  .slider__two-text,
  .slider__three-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    padding-bottom: 22px;
    width: calc(100% - 80px);
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  .slider__one-navigation,
  .slider__two-navigation,
  .slider__three-navigation {
    padding: 18px;
    border: 2px solid red;
    text-decoration: none;
    background-color: inherit;
    color: black;
    border-radius: 5px;
    font-size: 20px;
    width: calc(100% - 80px);
    margin: 0 auto;
    margin-left: 40px;
  }

  .slider__one-navigation:hover,
  .slider__two-navigation:hover,
  .slider__three-navigation:hover {
    background-color: red;
    color: white;
  }

  .slider__one-image,
  .slider__two-image,
  .slider__three-image {
    width: 100%;
    height: 500px;
    object-fit: contain;
    padding: 30px 0;
  }

  .hidden {
    display: none;
  }
}

@media screen and (max-width: 600px) {

  .slider__one-text,
  .slider__two-text,
  .slider__three-text {
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 20px;
    width: calc(100% - 50px);
  }

  .slider__one-navigation,
  .slider__two-navigation,
  .slider__three-navigation {
    margin-left: 25px;
    padding: 10px;
  }

  .slider__one-image,
  .slider__two-image,
  .slider__three-image {
    height: 400px;
  }
}

@media screen and (max-width: 400px) {

  .slider__one-text,
  .slider__two-text,
  .slider__three-text {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 10px;
    width: calc(100% - 30px);
  }

  .slider__one-navigation,
  .slider__two-navigation,
  .slider__three-navigation {
    margin-left: 15px;
    font-size: 17px;
    padding: 10px;
  }

  .slider__one-image,
  .slider__two-image,
  .slider__three-image {
    height: 330px;
  }
}