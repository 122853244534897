.header {
  padding: 60px 0;
  position: relative;
  overflow: hidden;
  height: 80vh;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header__description {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding-left: 20px;
  z-index: 1;
  color: #fff;
  opacity: 0.8;
  font-size: 40px;
  overflow: hidden;
  white-space: nowrap;
}

.header__about {}

.header__content {
  display: flex;
  width: calc(100% - 140px);
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header__icon {
  width: 150px;
  height: 65px;
  margin-right: 45px;
  position: relative;
}

.header__container {}

.header__navigation {}

.header__list {
  display: flex;
  list-style-type: none;
}

.header__list_info {
  color: black;
  text-align: right;
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none;
  margin-left: 80px;
  text-transform: uppercase;
}

.header__about_link {
  border-style: none;
  position: absolute;
  left: 20px;
  bottom: 10px;
}

.active {
  font-weight: bold;
  color: #EE3465;
  padding-bottom: 4px;
  border-bottom: 2px solid red;
}

.header__menu,
.header__close {
  display: none;
  cursor: pointer;
}

.header__phone {
  display: none;
}

@media screen and (max-width: 1160px) {
  .header {
    padding: 40px 0;
  }

  .header__list_info {
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    margin-left: 50px;
  }
}

@media screen and (max-width: 990px) {
  .header {
    padding: 25px 0;
  }

  .header__list_info {
    font-size: 17px;
    font-weight: 600;
    line-height: 16px;
    margin-left: 25px;
  }

  .header__content {
    width: calc(100% - 100px);
  }
}

@media screen and (max-width: 870px) {
  .header__list_info {
    font-size: 17px;
  }

  .header__content {
    width: calc(100% - 50px);
  }
}

@media screen and (max-width: 768px) {
  .header {
    height: 5vh;
  }

  .header__phone {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-image: url('../../images/headerPhone.svg');
    background-size: cover;
  }

  .header__icon {
    margin: 0;
    margin-left: 40%;
  }

  .video-container,
  .background-video,
  .header__description {
    display: none;
  }

  .header__menu,
  .header__close {
    display: block;
    width: 50px;
  }

  .header__list_info {
    color: #fff;
  }

  .header__menu.hidden,
  .header__close.hidden {
    display: none;
  }

  .header__navigation {
    display: none;
    position: fixed;
    top: 100px;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
  }

  .header__navigation.active {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .header__list {
    flex-direction: column;
    padding: 0;
  }

  .header__list_popup {
    padding: 35px;
  }

  .header__list_info {
    padding: 20px;
    text-align: center;
    color: #fff;
    font-size: 24px;
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 600px) {
  .header__icon {
    margin-left: 25%;
  }

  .header__phone {
    width: 45px;
    height: 45px;
  }

  .header__menu {
    width: 45px;
    height: 45px;
  }
}

@media screen and (max-width: 490px) {
  .header__icon {
    margin-left: 15%;
  }

  .header__phone {
    width: 35px;
    height: 35px;
  }

  .header__menu {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 375px) {
  .header__icon {
    margin-left: 10%;
  }
}