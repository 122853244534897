.sponsor {
  background-color: #F2F2F2;
  padding: 50px 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sponsor__header {
  word-wrap: break-word;
  white-space: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 34px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
  padding: 30px 20px 50px;
}

.sponsor__image {
  width: 300px;
  height: 200px;
  opacity: 0.6;
}

.sponsor__image:hover {
  opacity: 1;
}

.sponsor__image:nth-child(2) {
  padding-left: 200px;
}

@media screen and (max-width: 1000px) {
  .sponsor {
    padding: 10px 0 70px;
  }

  .sponsor__header {
    font-size: 15px;
    line-height: 34px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .sponsor__image {
    width: 150px;
    height: 150px;
  }

  .sponsor__image:nth-child(2) {
    padding-left: 150px;
  }
}

@media screen and (max-width: 700px) {
  .sponsor {
    padding: 10px 0 50px;
  }

  .sponsor__header {
    font-size: 12px;
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .sponsor__image {
    width: 110px;
    height: 110px;
  }

  .sponsor__image:nth-child(2) {
    padding-left: 110px;
  }
}

@media screen and (max-width: 450px) {
  .sponsor {
    padding: 10px 0 30px;
  }

  .sponsor__header {
    padding: 10px 5px 20px;
  }

  .sponsor__image {
    width: 110px;
    height: 110px;
  }

  .sponsor__container {
    display: flex;
    align-items: center;
  }

  .sponsor__image:nth-child(2) {
    padding-left: 0;
  }
}